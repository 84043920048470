import { ISummonerName } from '../types/lol/summoner.type';

export const getSummonerName = <T extends ISummonerName>(summoner: T) => {
  if (summoner?.gameName && summoner?.tagLine) {
    return `${summoner.gameName}#${summoner.tagLine}`;
  }
  return summoner.name;
};

export const splitSummonerName = (name: string) => {
  const tagIndex = name.indexOf('#');
  const result: {
    name: string;
    gameName?: string;
    tagLine?: string;
  } = {
    name,
  };
  if (tagIndex !== -1) {
    result.name = name.slice(0, tagIndex);
    result.gameName = name.slice(0, tagIndex);
    result.tagLine = name.slice(tagIndex + 1);
  }
  return result;
};

export const addRequestParamsWithSummonerName = (
  summonerName: string,
  obj: any
) => {
  const { name, gameName, tagLine } = splitSummonerName(summonerName || '');
  obj = {
    ...obj,
    summonerName: name,
  };
  if (gameName) {
    obj.gameName = gameName;
  }
  if (tagLine) {
    obj.tagLine = tagLine;
  }
  return obj;
};
