import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

interface Props {
  className?: string;
  name: string;
  alert?: string;
}

const NameCopyButton = ({
  className,
  name,
  alert = 'Summoner name copied to clipboard.',
}: Props) => {
  const intl = useIntl();
  return (
    <span
      id="summoner-name-copy"
      className={classNames(
        'text-3xs tracking-tighter whitespace-nowrap ml-1.5 px-1 py-0.5 rounded-sm hover:text-green-500 text-gray-300 bg-gray-900 animate-fade-in cursor-pointer',
        className
      )}
      onClick={handleCopyName}
    >
      <FormattedMessage id="Copy" />
    </span>
  );

  async function handleCopyName(
    event: React.MouseEvent<HTMLOrSVGElement>
  ): Promise<void> {
    event.stopPropagation();
    if (!navigator.clipboard) return;

    await navigator.clipboard.writeText(name);

    window.alert(intl.formatMessage({ id: alert }));
  }
};

export default NameCopyButton;
