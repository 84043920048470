import { ISummoner } from './lol/summoner.type';
import { Nullable } from './UtilTypes';

export interface IPartner {
  id: string;
  name: string;
  note?: string;
  passcode: string;
  insertedAt: Date;
  options: number;
  ipAddress?: string;
  isAuthor: boolean;
  isSubscription?: boolean;
}

export interface ITroll {
  id: string;
  summoner: ISummoner;
  note: string;
  tags: Nullable<string>;
  reportCount: number;
  insertedAt: Date;
}

export enum PartnerOption {
  MIC = 1 << 0,
  DISCORD = 1 << 1,
  // ...
  CERTIFIED = 1 << 10,
  PREMIUM = 1 << 11,
}

export type GameResult = 'WIN' | 'LOSE' | 'MVP' | 'ACE' | 'DRAW' | 'UNKNOWN';
