import { Region } from '../types/lol/summoner.type';
import { Locale } from '../types/LocaleTypes';

export const CONNECT_TIMEOUT = 10 * 1000;

export const REGION_FULL_NAME: {
  [key: string]: string;
} = {
  AP: 'Asia Pacific',
  LATAM: 'Latin America',
  LAS: 'LAS',
  LAN: 'LAN',
  NA: 'North America',
  EU: 'Europe',
  EUW: 'Europe West',
  EUNE: 'Europe Nordic & East',
  OCE: 'Oceania',
  KR: 'Korea',
  JP: 'Japan',
  BR: 'Brazil',
  RU: 'Russia',
  TR: 'Türkiye',
  SG: 'Singapore',
  PH: 'Philippines',
  TW: 'Taiwan',
  VN: 'Vietnam',
  TH: 'Thailand',
  ASIA: 'Asia',
  ME: 'Middle East',
};

export const LOL_REGION_TAG_NAME: Record<Region, string> = {
  [Region.LAS]: '#LAS',
  [Region.LAN]: '#LAN',
  [Region.NA]: '#NA1',
  [Region.EUW]: '#EUW',
  [Region.EUNE]: '#EUNE',
  [Region.OCE]: '#OCE',
  [Region.KR]: '#KR1',
  [Region.JP]: '#JP1',
  [Region.BR]: '#BR1',
  [Region.RU]: '#RU1',
  [Region.TR]: '#TR1',
  [Region.SG]: '#SG2',
  [Region.PH]: '#PH2',
  [Region.TW]: '#TW2',
  [Region.VN]: '#VN2',
  [Region.TH]: '#TH2',
  [Region.ME]: '#ME1',
};

export const NO_RSO_EXAMPPLE_MEMO_IDS = [
  'Looking for a DUO partner.',
  'Looking for a party!',
  'Looking for a player who has a mic.',
  'Looking for well mannered player.',
  'Looking for a serious player.',
  'Looking for someone to play DUO ranked.',
  'Looking for someone to play together.',
  'Looking for someone to play for 1 win.',
  'Looking for someone who wants a win streak.',
  'Looking for alt DUO partner.',
  'Looking for someone to climb the ranks together.',
];

export const LOCALE_LANGUAGE: Record<Locale, string> = {
  [Locale.KO]: '한국어',
  [Locale.EN]: 'English',
  [Locale.PT]: 'português',
  [Locale.ZH_TW]: '繁體中文',
  [Locale.TH]: 'ไทย',
  [Locale.VI]: 'Tiếng Việt',
  [Locale.JA]: '日本語',
};
