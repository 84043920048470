import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/pt';
import 'dayjs/locale/th';
import 'dayjs/locale/vi';
import 'dayjs/locale/zh-tw';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.extend(duration);

export type DateLike = Date | dayjs.Dayjs | string | number;

export function formatFullTime(date: DateLike): string {
  return dayjs(date).format('YYYY-MM-DD hh:mm:ss');
}

export function formatFromNow(date: DateLike, locale: string): string {
  return dayjs(date).locale(locale).fromNow();
}

export function formatDuration(ms: number, locale: string) {
  return dayjs
    .duration(ms * 1000)
    .locale(locale)
    .humanize();
}

export const replaceTimeUnits = (date: string): string => {
  date = date.replace('hours', 'hrs');
  date = date.replace('hour', 'hr');
  date = date.replace('minutes', 'mins');
  return date;
};
