import ReactDOM from 'react-dom';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export const AlertPortal: React.FC<Props> = ({ children }) => {
  const queryElement = document.querySelector('#_alert');

  if (!queryElement) {
    return <>{children}</>;
  }
  return ReactDOM.createPortal(children, queryElement);
};
