import classNames from 'classnames';
import Image from 'next/image';
import React from 'react';
import { MemberType } from '../types/MemberTypes';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  profileImageUrl: string;
  memberType: MemberType;
};

const ClanMemberProfileImage: React.FC<Props> = ({
  className,
  profileImageUrl,
  memberType,
}) => {
  return (
    <div className={classNames('flex-shrink-0 relative mr-2', className)}>
      <div className="w-8 h-8 relative">
        <Image
          src={profileImageUrl}
          fill
          alt="Profile image"
          className="rounded-full"
        />
      </div>
      {memberType !== 'MEMBER' && (
        <div className="absolute -right-0.5 top-0 w-[12px] h-[8px]">
          <img
            src={`//opgg-desktop-data.akamaized.net/download/duo/images/icons/clan-roles-${memberType.toLowerCase()}.png`}
            alt={memberType}
            className={'object-fill'}
          />
        </div>
      )}
    </div>
  );
};

export default ClanMemberProfileImage;
