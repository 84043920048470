import React, { FC, useMemo } from 'react';
import { SummonerOptionProps, SummonerProfile } from './index';
import { ISummonerName } from '../../types/lol/summoner.type';
import {
  resolveOpTagUrl,
  resolveOpUrl,
  resolveOverwatchOpUrl,
  resolveTFTOpTagUrl,
  resolveTFTOpUrl,
  resolveValorantOpUrl,
} from '../../utils/misc';
import { getRegion, getTFTRegion } from '../../utils/application';
import { getSummonerName } from '../../utils/tag';
import NameCopyButton from '../NameCopyButton';
import { TFTSummoner } from '../../types/tft/summoner.type';
import { ValorantPlayer } from '../../types/valorant/player.type';
import { OverwatchPlayer } from '../../types/overwatch/player.type';
import { MemberType } from '../../types/MemberTypes';
import ClanMemberProfileImage from '../ClanMemberProfileImage';
import { SteamUser } from '../../types/steam/partner.type';
import { FormattedMessage } from 'react-intl';

interface Props {
  profileImgSrc: string;
  summoner: ISummonerName;
  options: SummonerOptionProps;
}

const PartnerListItemProfile: FC<Props> = ({
  profileImgSrc,
  summoner,
  options,
}) => {
  return (
    <SummonerProfile>
      <SummonerProfile.Avatar src={profileImgSrc} />
      <div className="min-w-0">
        <div className="flex items-center max-w-fit min-h-[20px]">
          <SummonerProfile.Name
            name={summoner?.gameName ?? summoner.name}
            href={
              summoner?.gameName && summoner?.tagLine
                ? resolveOpTagUrl(
                    summoner.gameName,
                    summoner.tagLine,
                    getRegion()
                  )
                : resolveOpUrl(getSummonerName(summoner), getRegion())
            }
          />
          <SummonerProfile.Options {...options} />
          <NameCopyButton
            className="hidden group-hover:block"
            name={getSummonerName(summoner)}
          />
        </div>
        <SummonerProfile.Description
          tagLine={summoner?.tagLine}
          certified={options?.certified}
        />
      </div>
    </SummonerProfile>
  );
};

interface TFTProps {
  summoner: TFTSummoner;
  options: SummonerOptionProps;
}

export const TFTPartnerListItemProfile: FC<TFTProps> = ({
  summoner,
  options,
}) => {
  const region = useMemo(
    () => summoner?.region ?? getTFTRegion(),
    [summoner?.region]
  );
  return (
    <SummonerProfile>
      <SummonerProfile.Avatar
        src={`https://opgg-static.akamaized.net/meta/images/profile_icons/profileIcon${summoner.profileImageUrl}.jpg?image=q_auto,f_webp,w_64`}
      />
      <div className="min-w-0">
        <div className="flex items-center max-w-fit min-h-[20px]">
          <SummonerProfile.Name
            name={summoner?.gameName ?? summoner.name}
            href={
              summoner?.gameName && summoner?.tagLine
                ? resolveTFTOpTagUrl(
                    summoner.gameName,
                    summoner.tagLine,
                    region
                  )
                : resolveTFTOpUrl(getSummonerName(summoner), region)
            }
          />
          <SummonerProfile.Options {...options} />
          <NameCopyButton
            className="hidden group-hover:block"
            name={getSummonerName(summoner)}
          />
        </div>
        <SummonerProfile.Description
          tagLine={summoner?.tagLine}
          certified={options?.certified}
        />
      </div>
    </SummonerProfile>
  );
};

interface ValorantProps {
  player: ValorantPlayer;
  options: SummonerOptionProps;
}

export const ValorantPartnerListItemProfile: FC<ValorantProps> = ({
  player,
  options,
}) => {
  return (
    <SummonerProfile>
      <div className="min-w-0">
        <div className="flex items-center max-w-fit min-h-[20px]">
          <SummonerProfile.Name
            name={player.name}
            href={resolveValorantOpUrl(player)}
          />
          <SummonerProfile.Options {...options} />
          <NameCopyButton
            className="hidden group-hover:block"
            name={player.name + '#' + player.tagLine}
          />
        </div>
        <SummonerProfile.Description
          tagLine={player?.tagLine}
          certified={options?.certified}
        />
      </div>
    </SummonerProfile>
  );
};

interface OverwatchProps {
  player: OverwatchPlayer;
  options: SummonerOptionProps;
}

export const OverwatchPartnerListItemProfile: FC<OverwatchProps> = ({
  player,
  options,
}) => {
  return (
    <SummonerProfile>
      {player?.profileImageUrl && (
        <SummonerProfile.Avatar src={player.profileImageUrl} />
      )}
      <div className="min-w-0">
        <div className="flex items-center max-w-fit min-h-[20px]">
          <SummonerProfile.Name
            name={player.name}
            href={resolveOverwatchOpUrl(player)}
          />
          <SummonerProfile.Options useRiotIcon={false} {...options} />
          <NameCopyButton
            className="hidden group-hover:block"
            name={player.name + '#' + player.tagLine}
          />
        </div>
        <SummonerProfile.Description
          tagLine={player?.tagLine}
          certified={options?.certified}
        />
      </div>
    </SummonerProfile>
  );
};

interface MemberProps {
  profileImgSrc: string;
  memberType: MemberType;
  className?: string;
  summoner: ISummonerName;
}

export const MemberListItemProfile: FC<MemberProps> = ({
  profileImgSrc,
  summoner,
  memberType,
  className,
}) => {
  return (
    <SummonerProfile className={className}>
      <ClanMemberProfileImage
        profileImageUrl={profileImgSrc}
        memberType={memberType}
      />
      <div className="min-w-0">
        <div className="flex items-center max-w-fit min-h-[20px]">
          <SummonerProfile.Name
            name={summoner?.gameName ?? summoner.name}
            href={
              summoner?.gameName && summoner?.tagLine
                ? resolveOpTagUrl(
                    summoner.gameName,
                    summoner.tagLine,
                    getRegion()
                  )
                : resolveOpUrl(getSummonerName(summoner), getRegion())
            }
          />
          <NameCopyButton
            className="hidden group-hover:block"
            name={getSummonerName(summoner)}
          />
        </div>
        <SummonerProfile.Description tagLine={summoner?.tagLine} />
      </div>
    </SummonerProfile>
  );
};

interface TrollProps {
  profileImgSrc: string;
  summoner: ISummonerName;
}

export const TrollListItemProfile: FC<TrollProps> = ({
  profileImgSrc,
  summoner,
}) => {
  return (
    <li className="w-44 flex group items-center sm:whitespace-nowrap">
      <SummonerProfile.Avatar src={profileImgSrc} />
      <div className="min-w-0">
        <div className="flex items-center max-w-fit min-h-[20px]">
          <SummonerProfile.Name
            name={summoner?.gameName ?? summoner.name}
            href={
              summoner?.gameName && summoner?.tagLine
                ? resolveOpTagUrl(
                    summoner.gameName,
                    summoner.tagLine,
                    getRegion()
                  )
                : resolveOpUrl(getSummonerName(summoner), getRegion())
            }
          />
          <NameCopyButton
            className="hidden group-hover:block"
            name={getSummonerName(summoner)}
          />
        </div>
        <SummonerProfile.Description tagLine={summoner?.tagLine} />
      </div>
    </li>
  );
};

interface SteamProps {
  user: SteamUser;
  mic?: boolean;
  certified?: boolean;
}

export const SteamListItemProfile: FC<SteamProps> = ({
  user,
  certified = false,
  mic = false,
}) => {
  return (
    <SummonerProfile>
      <SummonerProfile.Avatar src={user.profileImage} />
      <div className="min-w-0">
        <div className="flex items-center max-w-fit min-h-[20px]">
          <SummonerProfile.Name
            name={user.personaName}
            href={user.profileUrl}
            tooltip={'View on Steam'}
          />
          <SummonerProfile.Options steamCertified={certified} mic={mic} />
          <NameCopyButton
            alert={'Player name copied to clipboard.'}
            className="hidden group-hover:block"
            name={user.personaName}
          />
        </div>
        <div className="text-3xs text-gray-500 text-left">
          {certified && <FormattedMessage id="Certified" />}
        </div>
      </div>
    </SummonerProfile>
  );
};

export default PartnerListItemProfile;
