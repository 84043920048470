import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ITranslateMessage } from '../types/AlertTypes';
import { ErrorCode } from '../types/ErrorTypes';
import { Nullable } from '../types/UtilTypes';

export const ErrorMessageMap: {
  [key in keyof typeof ErrorCode]: string;
} = {
  InvalidRecaptcha: 'InvalidRecaptcha',
  SummonerNotFound: 'SummonerNotFound',
  InvalidPassword: 'InvalidPassword',
  InvalidPasscode: 'InvalidPasscode',
  PasscodeNotAllowed: 'Enter 4 digits, please.',
  KeywordNotAllowed: 'KeywordNotAllowed',
  UrlNotAllowed: 'UrlNotAllowed',
  PhoneNumberNotAllowed: 'PhoneNumberNotAllowed',
  BannedIP: 'BannedIP',
  BannedSummoner: 'BannedSummoner',
  TooManyRequest: 'TooManyRequest',
  TooShortStringLength: 'TooShortStringLength',
  TooLongStringLength: 'TooLongStringLength',
  TooLongTitleStringLength: 'TooLongTitleStringLength',
  InvalidMemberNumber: 'InvalidMemberNumber',
  AlreadyReported: 'AlreadyReported',
  ReportCountExceeded: 'ReportCountExceeded',
  TemporarilyError: 'TemporarilyError',
  UnexpectedException: 'UnexpectedException',

  SUMMONER: 'Please input a summoner name.',
  POSITION: 'Please choose the position.',
  POSITION2: 'Please choose the secondary position.',
  CHAMPION: 'Please choose a champion.',
  CHAMPION2: 'Please choose a secondary champion.',
  SameChampion: 'The main and secondary champions are the same.',
  SamePosition: 'The main and secondary positions are the same.',
  QUEUE_TYPE: 'Please choose a queue type.',
  PASSWORD: 'Please input a passcode.',
  InvalidPatternPasscode:
    'Cannot use consecutive number sequences or repetitions.',
  INTRODUCE: 'Please enter an introductory message.',
  NOTE_MIN_LENGTH: 'Please enter at least 10 characters for the note.',
  NoTagLine: 'Please enter a tagline.',

  InvalidUser: 'InvalidUser',
  InvalidKakaoURL: 'InvalidKakaoURL',
  InvalidDiscordURL: 'InvalidDiscordURL',
  NotFoundClans: 'NotFoundClans',
  NotMatchRequiredTier: 'NotMatchRequiredTier',
  AlreadyExistClan: 'AlreadyExistClan',
  AlreadyRefreshed: 'AlreadyRefreshed',
  AlreadySubscriptionRefreshed: 'AlreadySubscriptionRefreshed',
  RegisterCountExceeded: 'RegisterCountExceeded',
  PartnerCreationRejected: 'PartnerCreationRejected',
  PartnerCreationLimited: 'PartnerCreationLimited',
  PressButtonLater: 'PressButtonLater',
  InvalidSummonerByRiot: 'InvalidSummonerByRiot',
  InvalidIpAddress: 'InvalidIpAddress',
  DuplicatedNote: 'DuplicatedNote',
  AlreadyCertifiedSummonerByOtherUser: 'AlreadyCertifiedSummonerByOtherUser',
  CertifiedSummonerNotFound: 'CertifiedSummonerNotFound',
  NotPlayedTogetherRecently: 'NotPlayedTogetherRecently',
  InvalidTier: 'InvalidTier',
  InvalidMemberLength: 'InvalidMemberLength',
  DuplicatedPosition: 'DuplicatedPosition',
  DuplicatedSummoner: 'DuplicatedSummoner',
  IsNotClashMaster: 'IsNotClashMaster',
  AlreadyExistClash: 'AlreadyExistClash',
  AlreadyJoinedClash: 'AlreadyJoinedClash',
  AlreadyJoinedOtherClash: 'AlreadyJoinedOtherClash',
  AlreadyInviteUser: 'AlreadyInviteUser',

  PLAYER: 'Please input a player name.',
  TIER: 'Please choose a tier.',
  AGENT_CLASS: 'Please choose a class.',
  PlayerNotFound: 'PlayerNotFound',
  PrivateProfile: 'PrivateProfile',
  AlreadyCertifiedPlayerByOtherUser: 'AlreadyCertifiedPlayerByOtherUser',
  BannedPlayer: 'BannedPlayer',

  ROLE: 'Please choose a role.',
  PrivateProfileChooseTier: 'PrivateProfileChooseTier',

  LessThanNumber: 'You can choose up to {number} champions',
  CantSelectDuplicate: 'CantSelectDuplicate',

  CanJoinOnlyOne: 'CanJoinOnlyOne',

  InvalidSteamId: 'Please double-check your Steam ID and try again.',

  AlreadyJoinedEmail: 'email-registration.duplicated',
};

interface Props {
  message: ITranslateMessage;
  errorCode: Nullable<ErrorCode>;
  className?: string;
  hide: () => void;
}

const Alert: React.FC<Props> = ({ message, errorCode, className, hide }) => {
  return (
    <p
      className={classNames(
        'fixed left-1/2 cursor-pointer break-words rounded text-sm bg-gray-700 py-3 px-4 shadow-lg z-[100001] transform -translate-x-1/2 -translate-y-1/2 lg:max-w-6xl sm:max-w-prose',
        className
      )}
      role="alert"
      onClick={hide}
    >
      <FormattedMessage id={message.key} values={message.value} />
      {errorCode && message.key === ErrorCode.UnexpectedException && (
        <span className="text-gray-500"> ({errorCode})</span>
      )}
    </p>
  );
};

export default Alert;
