export enum ErrorCode {
  InvalidRecaptcha = 'InvalidRecaptcha',
  SummonerNotFound = 'SummonerNotFound',
  InvalidPassword = 'InvalidPassword',
  InvalidPasscode = 'InvalidPasscode',
  PasscodeNotAllowed = 'PasscodeNotAllowed',
  KeywordNotAllowed = 'KeywordNotAllowed',
  UrlNotAllowed = 'UrlNotAllowed',
  PhoneNumberNotAllowed = 'PhoneNumberNotAllowed',
  BannedIP = 'BannedIP',
  BannedSummoner = 'BannedSummoner',
  TooManyRequest = 'TooManyRequest',
  TooShortStringLength = 'TooShortStringLength',
  TooLongStringLength = 'TooLongStringLength',
  TooLongTitleStringLength = 'TooLongTitleStringLength',
  InvalidMemberNumber = 'InvalidMemberNumber',
  AlreadyReported = 'AlreadyReported',
  ReportCountExceeded = 'ReportCountExceeded',
  TemporarilyError = 'TemporarilyError',
  UnexpectedException = 'UnexpectedException',

  SUMMONER = 'SUMMONER',
  POSITION = 'POSITION',
  POSITION2 = 'POSITION2',
  CHAMPION = 'CHAMPION',
  CHAMPION2 = 'CHAMPION2',
  SameChampion = 'SameChampion',
  SamePosition = 'SamePosition',
  QUEUE_TYPE = 'QUEUE_TYPE',
  PASSWORD = 'PASSWORD',
  InvalidPatternPasscode = 'InvalidPatternPasscode',
  INTRODUCE = 'INTRODUCE',
  NOTE_MIN_LENGTH = 'NOTE_MIN_LENGTH',
  NoTagLine = 'NoTagLine',

  InvalidUser = 'InvalidUser',
  InvalidKakaoURL = 'InvalidKakaoURL',
  InvalidDiscordURL = 'InvalidDiscordURL',
  NotFoundClans = 'NotFoundClans',
  NotMatchRequiredTier = 'NotMatchRequiredTier',
  AlreadyExistClan = 'AlreadyExistClan',
  AlreadyRefreshed = 'AlreadyRefreshed',
  AlreadySubscriptionRefreshed = 'AlreadySubscriptionRefreshed',
  RegisterCountExceeded = 'RegisterCountExceeded',
  PartnerCreationRejected = 'PartnerCreationRejected',
  PartnerCreationLimited = 'PartnerCreationLimited',
  InvalidSummonerByRiot = 'InvalidSummonerByRiot',
  InvalidIpAddress = 'InvalidIpAddress',
  DuplicatedNote = 'DuplicatedNote',
  AlreadyCertifiedSummonerByOtherUser = 'AlreadyCertifiedSummonerByOtherUser',
  CertifiedSummonerNotFound = 'CertifiedSummonerNotFound',
  NotPlayedTogetherRecently = 'NotPlayedTogetherRecently',

  // LoL Clash
  InvalidTier = 'InvalidTier',
  InvalidMemberLength = 'InvalidMemberLength',
  DuplicatedPosition = 'DuplicatedPosition',
  DuplicatedSummoner = 'DuplicatedSummoner',
  IsNotClashMaster = 'IsNotClashMaster',
  AlreadyExistClash = 'AlreadyExistClash',
  AlreadyJoinedClash = 'AlreadyJoinedClash',
  AlreadyJoinedOtherClash = 'AlreadyJoinedOtherClash',
  AlreadyInviteUser = 'AlreadyInviteUser',

  // Valorant
  PLAYER = 'PLAYER',
  TIER = 'TIER',
  AGENT_CLASS = 'AGENT_CLASS',
  PlayerNotFound = 'PlayerNotFound',
  PrivateProfile = 'PrivateProfile',
  AlreadyCertifiedPlayerByOtherUser = 'AlreadyCertifiedPlayerByOtherUser',
  BannedPlayer = 'BannedPlayer',

  // Overwatch
  ROLE = 'ROLE',
  PrivateProfileChooseTier = 'PrivateProfileChooseTier',

  LessThanNumber = 'LessThanNumber',
  CantSelectDuplicate = 'CantSelectDuplicate',

  // Steam
  InvalidSteamId = 'InvalidSteamId',

  // Event
  CanJoinOnlyOne = 'CanJoinOnlyOne',

  AlreadyJoinedEmail = 'AlreadyJoinedEmail',
  PressButtonLater = 'PressButtonLater',
}
