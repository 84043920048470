import React from 'react';
import {
  IAlert,
  IAlertShowOption,
  ITranslateMessage,
} from '../types/AlertTypes';
import { ErrorCode } from '../types/ErrorTypes';
import { Nullable } from '../types/UtilTypes';
import { useStateWithPartialSetter } from './utils';

export function useAlert(): {
  visible: boolean;
  errorCode: Nullable<ErrorCode>;
  message: ITranslateMessage;
  showAlert: (option: IAlertShowOption) => void;
  hideAlert: () => void;
} {
  const [state, setState] = useStateWithPartialSetter<IAlert>({
    visible: false,
    errorCode: null,
    message: {
      key: '',
    },
  });

  React.useEffect(() => {
    if (!state.visible) return;

    const timer = setTimeout(() => {
      hideAlert();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [state.visible]);

  function showAlert({ message, errorCode }: IAlertShowOption): void {
    setState({
      visible: true,
      message,
      errorCode,
    });
  }

  function hideAlert(): void {
    setState({
      visible: false,
      message: {
        key: '',
      },
      errorCode: null,
    });
  }

  return {
    visible: state.visible,
    errorCode: state.errorCode,
    message: state.message,
    showAlert,
    hideAlert,
  };
}
