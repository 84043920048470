import { Tooltip as MUITooltip, TooltipProps } from '@mui/material';
import React from 'react';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, any>;
};

const Tooltip: React.FC<TooltipProps & Props> = ({ children, ...rest }) => {
  return (
    <MUITooltip arrow disableInteractive {...rest}>
      {children}
    </MUITooltip>
  );
};

export default Tooltip;
