import { MicrophoneIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { PartnerOption } from '../types/PartnerTypes';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  useRiotIcon?: boolean;
  option: PartnerOption;
};

const PartnerOptionIcon: React.FC<Props> = ({
  className,
  useRiotIcon = true,
  option,
}) => {
  const intl = useIntl();

  switch (option) {
    case PartnerOption.PREMIUM:
      return (
        <img
          src="//opgg-desktop-data.akamaized.net/download/duo/images/icons/premium.svg"
          width={20}
          height={20}
        />
      );
    case PartnerOption.MIC:
      return (
        <MicrophoneIcon
          className={classNames('w-3 h-3 text-yellow-400', className)}
        />
      );
    case PartnerOption.CERTIFIED:
      return useRiotIcon ? (
        <img
          className="p-[1px] bg-[#EC0029] rounded-[10px] box-content"
          src="//opgg-desktop-data.akamaized.net/download/duo/images/icons/riot.svg"
          width={14}
          height={14}
          alt={intl.formatMessage({ id: 'Certified' })}
        />
      ) : (
        <img
          src="//opgg-desktop-data.akamaized.net/download/duo/images/icons/certified.svg"
          width={16}
          height={16}
          alt={intl.formatMessage({ id: 'Certified' })}
        />
      );
    case PartnerOption.DISCORD:
    default:
      return <React.Fragment />;
  }
};

export default PartnerOptionIcon;
