import React, { FC, ReactNode } from 'react';
import { Avatar } from '@mui/material';
import classNames from 'classnames';
import Tooltip from '../Tooltip';
import { FormattedMessage, useIntl } from 'react-intl';
import { PartnerOption } from '../../types/PartnerTypes';
import PartnerOptionIcon from '../PartnerOptionIcon';
import SteamIcon from '../SteamIcon';

export interface SummonerOptionProps {
  premium?: boolean;
  certified?: boolean;
  mic?: boolean;
}

const SummonerProfileAvatar: FC<{ src: string }> = ({ src }) => {
  return <Avatar sx={{ width: 32, height: 32, marginRight: 1 }} src={src} />;
};

const SummonerProfileName: FC<{
  name: string;
  href: string;
  tooltip?: string;
}> = ({ name, href, tooltip = 'See details at OP.GG' }) => {
  const intl = useIntl();
  return (
    <Tooltip title={intl.formatMessage({ id: tooltip })}>
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className="truncate text-2xs tracking-tight hover:underline hover:text-green"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {name}
      </a>
    </Tooltip>
  );
};

const SummonerProfileDescription: FC<{
  tagLine?: string;
  certified?: boolean;
}> = ({ tagLine, certified = false }) => {
  return (
    <div className="text-3xs text-gray-500">
      {tagLine && <>#{tagLine}</>}
      {certified && (
        <span>
          {' '}
          {tagLine && <> / </>}
          <FormattedMessage id="Certified" />
        </span>
      )}
    </div>
  );
};

const SummonerProfileOptions: FC<{
  premium?: boolean;
  certified?: boolean;
  steamCertified?: boolean;
  mic?: boolean;
  useRiotIcon?: boolean;
}> = ({ premium, certified, steamCertified, mic, useRiotIcon = true }) => {
  return (
    <span className="flex items-center gap-0.5 ml-1 group-hover:hidden">
      {/*{premium && (*/}
      {/*  <PartnerOptionIcon*/}
      {/*    useRiotIcon={useRiotIcon}*/}
      {/*    option={PartnerOption.PREMIUM}*/}
      {/*  />*/}
      {/*)}*/}
      {certified && (
        <PartnerOptionIcon
          useRiotIcon={useRiotIcon}
          option={PartnerOption.CERTIFIED}
        />
      )}
      {steamCertified && <SteamIcon size={'small'} />}
      {mic && (
        <PartnerOptionIcon
          useRiotIcon={useRiotIcon}
          option={PartnerOption.MIC}
        />
      )}
    </span>
  );
};

const SummonerProfileMain: FC<{
  children: ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div
      className={classNames(
        'w-44 flex group items-center px-2.5 py-2 sm:whitespace-nowrap',
        className
      )}
    >
      {children}
    </div>
  );
};

export const SummonerProfile = Object.assign(SummonerProfileMain, {
  Avatar: SummonerProfileAvatar,
  Name: SummonerProfileName,
  Description: SummonerProfileDescription,
  Options: SummonerProfileOptions,
});
